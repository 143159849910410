    
.passed {
  padding-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between; }
  
  .passed-event {
  width: 658px;
  height: 172px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 5px;
  position: relative;
  color: #3C495B;
  margin-bottom: 35px; }
  
  .passed-event-block-logo{
    width: 170px;
  }
  .passed-event__logo {
  margin: 25px auto; }
  
  .passed-event span {
  font-size: 16px;
  font-weight: 700; }
  
  .passed-event__date {
    width: 50%;
  font-size: 18px;
  font-weight: 400; }
  
  .passed-event__name {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 27px;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
  }
  
  .passed-event__result {
  position: relative;
  margin-bottom: 8px; }
  
  .passed-event__result .img-clock {
  margin: 2px 14px auto 22px;
  background: url("/assets/img/snip.svg") no-repeat;
  background-position: 1px -32px;
  width: 16px;
  height: 16px; }
  
  .passed-event__credited .img-reward {
  margin-left: 15px;
  margin-right: 14px;
  background: url("/assets/img/snip.svg") no-repeat;
  background-position: 0 -56px;
  width: 14px;
  height: 19px; }
  
  .passed-event__result, .passed-event__credited {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500; }
  
  .passed-event-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 350px;
  padding-top: 25px;
  padding-bottom: 39px; }
  
  .passed-event-block2 {
  width: 191px;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 39px;
  border-left: 1px solid #F3F3F3; }
  
  .passed-event__reviews, .passed-event__photo {
  text-align: center;
  width: 138px;
  height: 35px;
  border: 1px solid #DEE1E4;
  background: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  font-size: 14px;
  padding: 6px 16px; 
  transition: .7s;
    &:focus, &:active, &:hover{
      background: #DEE1E4;
    }
  }
  
  .passed-event__reviews-not, .passed-event__photo-not {
  background: #F2F2F2;
  border: none; }
  
  .passed-event__reviews {
  margin-bottom: 38px; }
  
  .passed-event__reviews span {
  font-weight: bold;
  font-size: 14px;
  color: #4CC29E; }
  
  .passed-event__reviews-add {
  padding: 6px;
  font-weight: 700;
  border-color: #FFC400; }
  
  .passed-event__result hr {
  position: absolute;
  top: -25px;
  left: 0px;
  width: 27px;}
  
  .passed-event__end {
  padding-top: 20px;
  width: 144px;
  margin: 0 auto;
  text-align: center;
  color: #DE6A63;
  font-weight: 700;
  z-index: 1; }
  
  .passed-event__end:before {
  content: '';
  position: absolute;
  width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.7;
  border-radius: 5px;
  z-index: 0; }
  
  .passed-event__end .img-close-red {
  margin: 0 auto;
  background: url("/assets/img/snip.svg") no-repeat;
  background-position: -100px -50px;
  width: 27px;
  height: 32px;
  margin-bottom: 10px;}