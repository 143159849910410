.admin-panel{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.quest_admin{
  display: flex;
  width: 658px;
  height: 188px;
  background: white;
  padding: 0;
  padding: 40px 25px;
  flex-wrap: wrap;
  height: 188px;
  border-radius: 5px;
  position: relative;
  color: #3C495B;
  .img{
    width: 99px;
    height: 99px;
  }
  .cabinet-user{
    width: 45%;
    &-data{
      margin-top: 15px;
    }
  }
  .cabinet-level{
    width: 50%;
    padding-left: 5%;
  }
  .invitation{
    display: flex;
    width: 100%;
    height: 50px;
  }
  &_title{
    width: 100%;
    height: 50px;
    word-wrap: break-word;
    font-weight: 700;
    color: #3C495B;
    overflow: hidden;
  }
  .searchBlock{
    border: 1px solid #DEE1E4;
    border-right: 0px;
  }
  .seacrhField{
    width: 50%;
    margin: 0;
    border: 1px solid #DEE1E4;
  }
  button{
    margin-right: 0px;
    margin-left: 72px;
  }
}