
label{
min-height: 15.4px;
}

button,
a {
  cursor: pointer !important;
}

.btn-success {
  border: 1px solid #FFC400 !important;
  background-color: #FFC400 !important;
  color: #3c495b;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 700;
  color: #333 !important;
  border-radius: 6px;
}

.pull-right {
  float: right;
}

.form {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #fff;
  border-radius: 10px;
  padding: 40px 30px;
  position: relative;
  margin-bottom: 150px;
}

.input-group {
  input {
    padding-left: 15px;
    color: #313131;
  }

  span {
    padding: 11px 10px;
    margin-right: -1px;
    text-align: center;
    background: #f3f5f6;
    border-radius: 6px 0 0 6px;
    border: 1px solid #D9E0EA !important;
    width: 50px;
  }
}

.form-group {
  font-size: 14px;
  font-weight: 300;
  color: #9198a2;
}

.ql-editor {
  min-height: 400px;
}

.form-control {
  height: 46px !important;
  outline: none !important;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid $orangeColor;
  }
}

.control-label {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.ember-basic-dropdown-trigger {
  padding-top: 10px;
}

.ember-power-select-search-input {
  outline: none;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.time {
  padding-left: 0;
  margin-left: 5px;
  max-width: 294px;
  height: 66px;
  border-bottom: 1px solid $borderColor;

  label {
    margin-left: -5px;
    margin-bottom: 15px;
  }

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid $orangeColor;
  }

  .form-control {
    border: 0px;
    margin-left: -25px;
    height: 20px;
  }
}

.timeMin {
  padding-top: 35px;

  span {
    font-size: 16px;
    color: $greyColor;
    padding-left: 20px;
    border-left: 1px solid $borderColor;
  }
}

.age {
  margin-left: 5px;
  max-width: 294px;
  height: 66px;
  border-bottom: 1px solid $borderColor;

  label {
    margin-left: -90px;
    margin-bottom: 15px;
  }

  &:hover,
  &:active,
  &:focus {
    border-bottom: 1px solid $orangeColor;
  }

  .form-control {
    border: 0px;
    margin-left: -30px;
    height: 20px;
  }
}

.ageFrom {
  padding-top: 35px;

  span {
    font-size: 16px;
    color: $greyColor;
    padding-right: 20px;
    border-right: 1px solid $borderColor;
  }
}


// .number{
//   margin-left: 5px;
//   max-width: 294px;
//   height: 66px;
//   border-bottom: 1px solid $borderColor;
//   label{
//     margin-left: -5px;
//     margin-bottom: 15px;
//   }
//   &:hover,&:active, &:focus{
//     border-bottom: 1px solid $orangeColor;
//   }
//   .form-control{
//     border: 0px;
//     height: 20px;
//   }
// }
// .numberFrom{
//   padding-top: 35px;
//   span{
//     font-size: 16px;
//     color: $greyColor;
//     padding-right: 20px;
//     border-right: 1px solid $borderColor;
//   }
// }
// .numberTo{
//   padding-top: 35px;
//   span{
//     font-size: 16px;
//     color: $greyColor;
//     padding-right: 20px;
//     border-right: 1px solid $borderColor;
//   }
// }

.form_column {
  .form_row {
    justify-content: space-between;
  }
}




.form-group .ember-power-select-trigger {
  font-size: 16px;
  color: #313131;
  min-height: 46px;
  border: 1px solid #ced4da !important;
  padding: 6px 15px;

  &:hover,
  &:focus,
  &:active {
    border: 0px;
    border-bottom: 1px solid $orangeColor;
    outline: none;
    box-shadow: none;
  }
}

.ember-power-select-option[aria-current=true] {
  background: $orangeColor !important;
  color: #ffffff;
}

.form .btn {
  margin-top: 26px;
  padding: 10px 20px;
  padding-left: 40px;
  font-size: 14px;
  height: 45px;
  font-weight: 700;
  background: url("/assets/img/plus.svg") 15px 13px no-repeat;
  color: $greyColor;
  border: 1px solid $orangeColor;
  border-radius: 5px;

  &:hover,
  &:focus,
  &:active {
    background: url("/assets/img/plus.svg") 15px 13px no-repeat;
    background-color: $orangeColor !important;
    color: $greyColor !important;
    box-shadow: none !important;
    border: 1px solid $orangeColor !important;
  }
}

.phonVersion {
  display: flex;
  align-items: flex-start;
  min-height: 120px;

  .phonVersionExample {
    &.active {
      border: 3px solid #f8c433;
    }
    width: 160px;
    height: 90px;
    text-align: center;
    padding: 20px 0;
    margin-right: 35px;
    border-radius: 12px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      b {
        display: block;
      }
    }
    b {
      cursor: pointer;
      margin-bottom: 5px;
      display: inline-block;
      display: none;
      font-size: 12px;
      color: #fff;
      background: #000;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   width: 25px;
    //   height: 25px;
    //   top: -12px;
    //   right: -10px;
    //   background: url('/assets/img/snip.svg');
    //   background-color: #fff;
    //   border-radius: 100%;
    //   background-position: -101px -6px;
    //   cursor: pointer;
    // }
  }
}

.save {
  position: absolute;
  bottom: -100px;
  right: 0;

  .btn {
    height: 50px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 40px;
    margin-right: 25px;

    &:last-child {
      margin-right: 0px
    }

    &:hover,
    &:focus,
    &:active {
      background: #fff;
    }

    &-cansel {
      border: 1px solid #B1BDC5 !important;
      border-radius: 5px;
      color: #B1BDC5;
      &:hover{
        border: 1px solid #B1BDC5 !important;
        background-color: #B1BDC5 !important;
        color: #3C495B;
      }
    }

    &-danger {
      border-color: #DE6A63 !important;
      color: #DE6A63 !important;
      &:hover{
        border: 1px solid #DE6A63 !important;
        background-color: #DE6A63 !important;
        color: #fff !important;
      }
    }

  }
}
