.ember-burger-menu.bm--squeeze {
	> .bm-outlet {
		margin-left: 0;
		margin-right: 0;
		width: auto;
		box-sizing: border-box;
	}

	#{$bm-menu} {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	&.right {
		#{$bm-menu} {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
		}
	}
}
