.ember-burger-menu.bm--push-rotate {
	-webkit-perspective: 1500px;
	perspective: 1500px;

	> .bm-outlet {
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}

	#{$bm-menu} {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);

		&::after {
			display: none;
		}
	}

	&.right {
		#{$bm-menu} {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
		}
	}

	&.is-open {
		> .bm-outlet {
			pointer-events: none;
		}

		#{$bm-menu} {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
}
