/*quests*/
.my-quests{
  display: flex;
  flex-wrap: wrap;
}
.questPhoto {
  width: 100px;
  height: 70px;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  margin-right: 20px;

  span {
    margin: 5px;
    font-size: 10px;
    background: #333;
    color: #fff;
    display: none;
    cursor: pointer;
  }

  &.active {
    border: 3px solid #000;
  }
  
  &:hover {
    span {
      display: block;
    }
  }
}
.uploadPhoto {
  margin-right: 20px;
  width: 100px;
  height: 70px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;

  a {
    font-size: 20px;
    width: 100px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.quest{
  font-size: 16px;
  display: block;
  width: 290px;
  color: black;
  background: #fff;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 53px;
  padding-bottom: 40px;
  border-radius: 5px;
  position: relative;
  span{
    display: inline-block;
    max-width: 240px;
    word-wrap: break-word;
  }
  img{
    display: inline;
    margin: 25px 0;
    width: 240px;
    height: 240px;
  }
  .img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 240px;
    height: 240px;
    border-radius: 100%;
    margin: 25px auto;
  }
  .edit-img{
    display: none;
    width: 240px;
    height: 240px;
    margin-left: 10px;
    margin-right: 10px;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #FFC400;
    }
  }
  &:last-child{
    margin-right: 0;
    &:hover{
      background: white;
      color: black;
      img{
        display: inline;
      }
    }
  }
  transition: 0.7s;
  &:hover{
    color: #fff;
    text-decoration: none;
    background-color: #FFC400;
    span{
      width: 100%;
      overflow: hidden;
    }
    .img{
      display: none !important;
    }
    .edit-img{
      display: inline;
    }
  }
}