.chargeBonus {
  float: right;
  border: 1px solid $orangeColor;
  background-color: #F5F7F8;
  color: #3c495b;
  margin-top: -7px;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 700;

  &:active,
  &:focus,
  &:hover {
    border: 1px solid $orangeColor !important;
    background-color: #F5F7F8 !important;
    color: #3C495B !important;
    box-shadow: none !important;
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.viewNavWrap {
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border-color: #f8c333;
    background: #f8c333;
    padding: 10px 20px;
    border-radius: 6px;
    margin-left: 20px;
    outline: none !important;
    position: absolute;
    top: -64px;
    right: 0;
    &:hover{
      background: #6FBECF;
      border-color: #6FBECF;
      color: white;
    }
  }

  a {
    padding: 3px 0;
    font-size: 14px;
    margin-right: 22px;
    color: #B1BDC5;
    font-weight: 700;
    text-decoration: none !important;

    &.active {
      color: #5f707c;
    border-bottom: 3px solid #f8c333;
    }

    &:active,
    &:focus,
    &:hover {
      color: #5f707c;
    }
  }
}
