.searchBlock{
  background: #fff;
  width: 50px;
  height: 48px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0 0 5px;
  .searchImg{
    float: left;
    width: 18px;
    height: 18px;
    background: url('/assets/img/snip.svg') center center no-repeat;
    background-position: -50px -30px;
  }
}
.seacrhField{
  width: 490px;
  height: 48px;
  border: none;
  text-align: center;
  border-radius: 0 5px 5px 0;
  margin-right: 50px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #3C495B;
  outline: none;
  border-left: 1px solid#E9F1F7;
  &:placeholder{
    color: #B1BDC5;
  }
}
.seacrhFieldbutton{
  margin-right: 34px;
  height: 47px;
  border: 1px solid #FFC400;
  border-radius: 5px;
  padding: 0 45px;
  font-size: 14px;
  font-weight: 700;
  color: #3C495B;
  &:hover, &:focus,&:active{
    box-shadow: none !important;
    border: 1px solid #FFC400 !important;
    color: #3C495B !important;
    background: #FFC400 !important;

  }
}