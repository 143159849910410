.ember-power-calendar {
    box-shadow: 0 0 10px #00000040;
    border-radius: 6px;
    padding: 5px 10px;
    .ember-power-calendar-day {
        padding: 10px 0;
        width: 40px;
        margin: 1px;
        outline: none !important;
    }

    .ember-power-calendar-weekday {
        font-size: 10px;
        color: #ccc;
    }

    .ember-power-calendar-nav {
        padding: 10px;
    }

    .ember-power-calendar-day--selected, .ember-power-calendar-day--selected:not([disabled]):hover {
        background: #f8c333;
    }
}