.ember-burger-menu.bm--slide-reverse {
	#{$bm-menu} {
		z-index: 1;
		-webkit-transform: translate3d(50%, 0, 0);
		transform: translate3d(50%, 0, 0);
	}

	&.right {
		#{$bm-menu} {
			-webkit-transform: translate3d(-50%, 0, 0);
			transform: translate3d(-50%, 0, 0);
		}
	}

	&.is-open {
		#{$bm-menu} {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
}
