.bm_open {
    position: absolute;
    left: 10px;
    top: 20px;
    background: transparent;
    border: 0;
    color: #3b485b;
    font-size: 26px;
}

.bm-menu {
    overflow-y: auto;
    max-height: 100%;
    background-color: #fff;

    header {
        width: 100% !important;
    }

    .header-wrap {
        width: 100%;
    }

}

.bm_close {
    position: absolute;
}