.loader,
.loader:before,
.loader:after {
  background: #cfd5db;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 10px;
  height: 30px;
}
.loader {
  color: #cfd5db;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}




.lightTableWrap td .link {
  color: #505B6F;
  border-bottom: 1px dashed #DADADA;
  cursor: pointer;
  margin-bottom: 7px;
  line-height: 18px;
  display: inline-block;
}

table p {
  margin: 0 0 0 0;
}


.tableSearch {
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 10px #dadada;
    border-radius: 6px;
    overflow: hidden;


  i {
    font-size: 20px;
    position: absolute;
    left: 20px;
    color: #ccc;
    top: 15px;
  }

  button {
    border-radius: 10px;
    border: 2px solid #f8c636;
    background: #fff;
    padding: 8px 24px;
    margin-right: 20px;
  }

  input{
    padding: 15px 20px 15px 50px;
    outline: none!important;
    width: 100%;
    border: 0;
  }
}

.datepicker td, .datepicker th {
  padding: 6px;
}

.lightTableWrap th {
  color: #a9aeb6;
}
  
.lightTableWrap {
  clear: both;
  background: #fff;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #F0F0F3;
  padding-bottom: 55px;
}

.lightTableWrap table {
  width: 100%;
}

.withHover tr {
  cursor: pointer;

  &:hover {
    background: #fdfdfd;
  }
}

.lightTableWrap td {
  border-bottom: 1px dotted #EEEEEE !important;
  border-width: 0 0 1px;
  border-style: solid;
  font-size: 14px;
  padding: 15px;
}


.lightTableWrap th {
  border-bottom: 1px solid #F2F3F4;
  padding: 20px 15px !important;
  color: #A4A4A4;
  font-weight: 200;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  i {
    float: none !important;
  }
}


.lightTableWrap td:first-child,
.lightTableWrap th:first-child {
  padding-left: 30px !important;
  box-sizing: content-box;
}

.lightTableWrap td:last-child,
.lightTableWrap th:last-child {
  padding-right: 30px !important;
  box-sizing: content-box;
}
