.audioButton {
    border: 1px solid #ccc;
    padding: 10px 20px;
    margin-top: 10px;
}

.audiojs audio {
	position: absolute;
	left: -1px;
}

.audiojs {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 60px;
	background: #f8c333;
	overflow: hidden;
	z-index: 999999999;
	display: flex;
	align-items: center;
}

.audiojs .play-pause {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	padding: 4px 6px;
	margin: 0px;
	float: left;
	overflow: hidden;
	border-right: 1px solid #bc9a30;
}

.audiojs p {
	display: none;
	width: 30px;
	height: 30px;
	margin: 0px;
	cursor: pointer;
}

.audiojs .play {
	display: block;
}

.audiojs .scrubber {
	position: relative;
	width: 100%;
	background: #bb9428;
	height: 10px;
	border-radius: 25px;
	margin: 0 25px;
	overflow: hidden;
}

.audiojs .progress {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 10px;
	width: 0px;
	background: #3b485b;
	z-index: 1;
}

.audiojs .loaded {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 10px;
	background: #ab8725;
}

.audiojs .time {
	height: 60px;
	display: flex;
	padding: 0 20px;
	border-left: 1px solid #bc9a2f;
	align-items: center;
}

.audiojs .time em {
	padding: 0px 2px 0px 0px;
	color: #3b495b;
	font-style: normal;
}

.audiojs .time strong {
	padding: 0px 0px 0px 2px;
	font-weight: normal;
	color: #3b495b;
	background: transparent;
}

.audiojs .error-message {
	float: left;
	display: none;
	margin: 0px 10px;
	height: 36px;
	width: 400px;
	overflow: hidden;
	line-height: 36px;
	white-space: nowrap;
	color: #fff;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-icab-text-overflow: ellipsis;
	-khtml-text-overflow: ellipsis;
	-moz-text-overflow: ellipsis;
	-webkit-text-overflow: ellipsis;
}

.audiojs .error-message a {
	color: #eee;
	text-decoration: none;
	padding-bottom: 1px;
	border-bottom: 1px solid #999;
	white-space: wrap;
}

.audiojs .play {
	background: url("/assets/img/icons/play.svg") center no-repeat;
}

.audiojs .loading {
	background: url("/assets/img/icons/play.svg") center no-repeat;
}

.audiojs .pause {
	background: url("/assets/img/icons/pause.svg") center no-repeat;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (min-moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2) {
	.audiojs .play,
	.audiojs .loading,
	.audiojs .error,
	.audiojs .pause {
		background-image: url("undefinedplayer-graphics@2x.gif");
		-webkit-background-size: 30px 120px;
		-moz-background-size: 30px 120px;
		-o-background-size: 30px 120px;
		background-size: 30px 120px;
	}
}

.playing .play,
.playing .loading,
.playing .error {
	display: none;
}

.playing .pause {
	display: block;
}

.loading .play,
.loading .pause,
.loading .error {
	display: none;
}

.loading .loading {
	display: block;
}

.closeAudio {
	background: #f8c333;
    font-size: 24px;
    color: #3a485b;
    display: flex;
    border: 0;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    bottom: 61px;
    z-index: 99999;
    width: 40px;
    height: 40px;
}

.error .time,
.error .play,
.error .pause,
.error .scrubber,
.error .loading {
	display: none;
}

.error .error {
	display: block;
}

.error .play-pause p {
	cursor: auto;
}

.error .error-message {
	display: block;
}