
.modal-dialog{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content{
  width: 462px;
  padding: 22px 26px 26px 26px;
  position: relative;
  .close{
    position: absolute;
    top: 34px;
    right: 36px;
    outline: none;
    border: 1px solid black;
    border-radius: 100%;
    padding: 0;
    width: 24px;
    height: 24px;
  }
  .modal-header{
    border: none;
    margin-bottom: 20px;
    .modal-title{
      font-size: 16px !important;
      color: #3C495B;
      font-weight: 700;
    }
  }
  .modal-body{
    padding-top: 0;
    .form-group{
      margin-bottom: 19px;
    }
  }
}
.big-btn{
  margin-left: 194px;
  margin-top: 15px;
  outline: none;
  background: #fff;
  font-weight: 700;
  border: 1px solid #FFC400;
  border-radius: 9px;
  width: 170px;
  height: 45px;
  &:active, &:focus,&:hover{
    outline: none;
  }
}