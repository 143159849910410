.acceptedTag {
  padding: 4px;
  font-weight: 700;
  color: #14B16B;
}

.allTag {
  padding: 4px;
  font-weight: 700;
  color: #ccc;
}

.canceledTag {
  padding: 4px;
  font-weight: 700;
  color: #DE6A63;
}

.ember-power-select-selected-item, .ember-power-select-placeholder {
    margin-left: 0px; 
}

.ember-power-select-multiple-option {
    border: 1px solid #dae0e9;
    border-radius: 4px;
    position: relative;
    top: -7px;
    color: #333333;
    background-color: #ffffff;
    padding: 5px 5px;
    display: inline-block;
    line-height: 1.45;
    float: left;
    margin: 2px 4px 2px 0px;
}


.statistic {
     width: 100%;
    border: 1px solid #f0f0f3;
    background: #fff;
    border-radius: 13px;
    margin-bottom: 25px;
}

.statistic th {
  font-weight: 300;
  padding: 10px;
}

.statistic td {
  font-weight: 700;
  padding: 10px;
}

.formElement {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #ced4da;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .label {
    border-left: 1px solid #f2f3f4;
    padding: 8px 15px;
    color: #ccc;
    font-size: 20px;
    width: 51px;
    
  }
  input {
    outline: none !important;
      width: 100% !important;
      height: 48px;
      border-radius: 5px;
      padding-left: 15px;
      margin-right: 15px;
    border: 0
  }
}

.visiting-nav {

  display: flex;
  margin-bottom: 20px;

  & > button {
    background: transparent;
    border-radius: 6px;
    margin-left: 20px;
    border-color: #f8c333;
    color: #f8c333;
    padding: 10px 20px;
  }



  input {
    width: 165px;
    height: 48px;
    border-radius: 5px;
    padding-left: 15px;
    margin-right: 15px;
  }

  div.form-group {
    margin-bottom: 0 !important;
    width: 100%;

    .form-control {
      border-bottom: 0px;
      border-radius: 0 5px 5px 0;
      text-align: center;
    }

    label {
      display: none;
    }

    .ember-power-select-selected-item,
    .ember-power-select-placeholder {
      margin-left: 8px;
      width: 186px !important;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ember-power-select-trigger {
      min-height: 48px;
      border-radius: 5px;
      padding: 10px 5px 0;
      margin-right: 15px;
      overflow: hidden;

      .ember-power-select-status-icon {
        right: 20px !important;

        &:after {
          content: "";
          position: absolute;
          height: 60px;
          width: 1px;
          background: #f5f7f8;
          right: 25px;
          top: -35px;
        }
      }
    }
  }

  .btn {
    padding: 10px 30px !important;
  }
}

.ember-power-select-search {
  //display: none;
}

.ember-power-select-option {
    cursor: pointer !important;
    padding: 8px 8px;
}

.ember-power-select-option {
    cursor: pointer !important;
    padding: 8px 8px;
}

.ember-power-select-dropdown {
  z-index: 999999;
    border-left: 1px solid #f0f0f3;
    border-right: 1px solid #f0f0f3;
border-bottom: 1px solid #f0f0f3;
    }

.ember-power-select-clear-btn {
  width: 25px;
  height: 25px;
  line-height: 25px;
  box-sizing: border-box;
  text-align: center;
  color: #C4C4C4;
  background: #fff;
  z-index: 2;
  border: 1px solid #C4C4C4;
  border-radius: 100%;
  bottom: 12px !important;
  right: 12px;
}

// 
.from-data,
.to-data {
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  min-width: 50px;
  height: 48px;
  color: #B1BDC5;
  font-weight: 500;
  background: #fff;
  border-right: 1px solid #EAEAEA;
}

.visit_ico {
  width: 28px;
  height: 27px;
  background: url(/assets/img/snip.svg) no-repeat;
  display: block;
  cursor: pointer;

  &.accepted {
    background-position: -101px -102px;
  }

  &.canceled {
    background-position: -101px -154px;

  }

  &.empty {
    background-position: -101px -6px;
  }
}

.visiting-status {
  display: flex;
  float: right;
  min-width: 238px;
  height: 48px;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  margin-top: 6px;

  .all {
    overflow: hidden;
    width: 62px;
    background: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 20px;
  }

  .yes,
  .no,
  .question {
    width: 60px;
    padding: 12px 20px;
    border-right: 1px solid #E9E9E9;
    background: url('/assets/img/snip.svg') no-repeat;

  }

  .yes {
    background-position: -81px -88px;
  }

  .no {
    background-position: -83px -139px;

  }

  .question {
    border-right: 0px;
    background-position: 18px -139px;
  }
}
