.favorite {
  padding-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .favorite img {
    margin-bottom: 12px; }
  .favorite-event {
    width: 182px;
    height: 235px;
    margin-right: 54px;
    margin-bottom: 35px;
    background: #fff;
    border-radius: 5px;
    padding-top: 18px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #3C495B;
    position: relative; }
    .favorite-event__point {
      position: absolute;
      bottom: 14px;
      right: 35px;
      width: 110px;
      height: 30px;
      padding-top: 4px;
      background-color: #FFF9E5;
      color: #F4BE0C;
      font-weight: 700;
      border-radius: 6px; }
    .favorite-event__name {
      padding: 0 30px; }
    .favorite-event__close {
      position: absolute;
      right: 9px;
      top: 6px;
      width: 26px;
      height: 26px;
      background: url("/assets/img/snip.svg");
      background-position-x: -100px;
      border: none; }
      .favorite-event__close:focus {
        outline: none; }
