/*fonts*/
/*styles*/
@import "application.scss";
@import "audio.scss";
@import "header.scss";
@import "quests.scss";
@import "edit.scss";

@import "ember-power-select/themes/bootstrap";

@import "ember-power-select";
@import "toastr.scss";
@import "tables.scss";
@import "login.scss";
@import "view.scss";
@import "visiting.scss";
@import "searchField.scss";
@import "client.scss";
@import "upcoming.scss";
@import "favorite.scss";
@import "pased.scss";
@import "modal.scss";
@import "quest-admin.scss";
@import "calendar.scss";
@import "burger_menu.scss";

@import "ember-bootstrap/bootstrap";
@import "ember-power-calendar";
@import "ember-burger-menu";

.default {
    display: block;
    width: 100%;
}

.categories {
  margin-bottom: 10px;

  ul {
    padding: 20px;
    border-radius: 10px;
  }


  li {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    &:hover {
      background: #eee;
    }
  }
}
