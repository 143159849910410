* {
  font-family: 'Montserrat', sans-serif
}

.login_wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F7F8;
}

.login {
  border-radius: 16px;
  width: 458px;
  background: #fff;

  img {
    padding-top: 5px;
    width: 70px;
    position: relative;
    top: -3px;
  }

  .login_header {
    margin: 0 121px 26.5px 114px;
    padding-top: 25px;
    width: 223px;
    text-align: center;

    div {
      line-height: 1.8;
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;

      span {
        color: #ffc400
      }
    }
  }

  form {
    padding: 0 61px 0 53px
  }

  .links {
    text-align: right
  }

  .error {
  	line-height: 26px;
    background: #ff1000b5;
    padding: 15px 30px;
    text-align: left !important;
    color: #fff !important;
    font-size: 12px !important;

  }

  .links a {
    display: block;
    letter-spacing: .2px;
    font-size: 14px;
    margin-right: 55px;
    margin-bottom: 8px;
    color: #778597;
    text-decoration: underline
  }

  .entry p,
  .login_header div {
    color: #3c495b
  }

  p {
    line-height: .7;
    letter-spacing: .6px;
    font-size: 12px;
    font-weight: 400
  }

  .entry {
    margin-bottom: 49px;
    .btn{
      &:hover, &:active {
        background-color: #FFC400 !important;
        color: #3c495b !important;
        border: 0;
      }
      &:disabled {
        background-color: #ccc !important;
        color: #3c495b !important;
        border: 0;

      }
      border: 1px solid #FFC400;
      background-color: #fff;
      color: #3c495b;
      margin-top: 10px;
      padding: 10px 35px;
      font-size: 14px;
      font-weight: 700;
      &:active,&:focus, &:hover{
        border: 1px solid #FFC400;
        background-color: #fff;
        color: #3c495b;
        box-shadow: none !important;
      }
    }
  }

  .entry p {
    font-size: 16px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 53px
  }
}
