.ember-burger-menu.bm--fall-down {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;

	#{$bm-menu} {
		z-index: 1;
		opacity: 1;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	&.is-open {
		#{$bm-menu} {
			-webkit-transition: -webkit-transform ease-in-out $bm-transition-duration;
			transition: transform ease-in-out $bm-transition-duration;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
}
