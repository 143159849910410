/*

Начало блока upcoming 

*/
/*обертка*/
.upcoming{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 35px;
}
/*обертка*/


/*родительнский блок*/
.upcoming-event {
display: flex;
width: 660px;
min-height: 230px;
background: #fff;
border-radius: 5px;
margin-bottom: 35px;}
/*родительнский блок*/


/*picture*/
.upcoming-event-picture {
min-width: 200px;
text-align: center;
border-right: 1px solid #F3F3F3; }

.upcoming-event-picture__logo {
margin: 30px auto;
margin-bottom: 27px; }

.upcoming-event-picture__button {
transition: .7s;
background: #fff;
height: 35px;
font-size: 14px;
font-weight: 700;
color: #DE6A63;
padding: 6px 10px;
border: 1px solid #DE6A63;
border-radius: 6px; }
.upcoming-event-picture__button:hover{
background: #DE6A63;
color: #fff;
}
.upcoming-event-picture__button:focus {
outline: none; }
/*picture*/

.one-block {
  margin-left: 30px;
  margin-right: 30px;
  padding: 27px 0;
  display: flex;
  width: 675px;
  flex-wrap: wrap;
  justify-content: space-between;
}

/**/
.upcoming-event-data {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
color: #3C495B;
margin-bottom: 25px;}

.upcoming-event-data__time,
.upcoming-event-data__price{
  height: 20px;
}


.upcoming-event-data__time,
.upcoming-event-data__address{
  width: 60%;
  text-align: left;
  word-wrap: break-word;
}
.upcoming-event-data__address{
  height: 75px;
  overflow: hidden;
}
.upcoming-event-data__people{
  align-items: center;

}
.upcoming-event-data__price,
.upcoming-event-data__people{
  display: flex;
  justify-content: flex-end;
  width: 40%;
}



.upcoming-event-data__time {
display: flex;
font-size: 18px; }

.upcoming-event-data__time .img-clock {
margin: 5px 12px 0;
width: 16px;
height: 16px;
background: url("/assets/img/snip.svg") no-repeat;
background-position-y: -32px; }

.upcoming-event-data__price {
font-size: 20px; }

.upcoming-event-data__time, 
.upcoming-event-data__price {
margin-bottom: 10px;
font-weight: 700; }


.upcoming-event-data__people{
  height: 25px;
}
.upcoming-event-data__people .img-user {
display: inline-block;
margin-right: 16px;
width: 14px;
height: 16px;
background: url("/assets/img/snip.svg") no-repeat; 
background-position: 0 -7px;}

/**/
.upcoming-event-desc{
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.upcoming-event-desc__text {
position: relative;
color: #3C495B;
width: 60%;
font-size: 14px;
line-height: 1.4;
  hr{
    position: absolute;
    width: 27px;
    background: #DCDCDC;
    top: -25px;
    left: 0px;
    margin: 0;
  }}
.upcoming-event-desc__text,.upcoming-event-desc__points{
display: flex;
align-items: flex-end;}

.upcoming-event-desc__points {
width: 40%;
text-align: right;
display: flex;
justify-content: flex-end;
  span{
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    background-color: #FFF9E5;
    color: #F4BE0C;
    font-weight: 700;
    border-radius: 6px;
    font-size: 18px; 
  }

}


/*

Конец блока upcoming 

*/