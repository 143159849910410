header {
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  box-shadow: 0 0 10px #0000001f;
}

.links-wrap {
  padding-top: 30px;
}

.topCorrect {
  position: relative;
  top: -5px;
  margin-bottom: 5px;
}

.header {
  ul {
    padding: 0;
    margin-top: 40px;
    li {
      a {
        padding: 17px 30px;
        display: block;
        border-bottom: 1px solid #f0f0f3;
        color: #3b495b;
        &.active {
          background: #f8c333;
        }
      }
    }
  }
}


/*logo*/
.logo{
  padding: 25px;
  img{
    float: left;
    margin-right: 20px;
    width: 40px;
    position: relative;
    top: -3px;
  }
  .title{
    display: flex;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    span{
      color: $orangeColor;
    padding-bottom: 0;
    border-bottom: 0;
    }
  }
  p{
    color: #3c495b;
    margin-bottom: 0;
    font-size: 9px;
    font-weight: 400;
    letter-spacing: 0.4px;



  }
}


/*exit*/
.exit{
  padding: 15px 30px;
    position: absolute;
    font-weight: 700;
    color: #3C495B;
    border: 1px solid #FFC400;
    border-radius: 5px;
    outline: none;
    transition: 0.7s;
    bottom: 40px;
    left: 25px;
}

.exit:hover{
  background-color:$orangeColor;
}

// new styles


.header-wrap {
  padding: 25px 20px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 100%;
}

.logo-bg {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.16);
  margin-right: 20px;
  
}

.logo-wrap {
  background-color: transparent;
  display: flex;
  align-items: center;
  outline: none !important;
  border: none;
  text-align: start;
  padding: 0 0;
  overflow: visible;
}

.logo_icon {
  width: 27px;
  height: 28px;
}

.logo-name-wrap {
  display: flex;
  flex-direction: column;
  max-width: 170px;
}

.bold-text {
  font-size: 14px;
  font-weight: bold;
  color: #3C495B;
  line-height: 100%;
}

.email_title {
  line-height: 110%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.faded-small-text {
  font-size: 12px;
  line-height: 100%;
  color: #919EAF;
  margin-top: 5px;
}

.balance-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  background: #FFF7DD;
  outline: none !important;
  border-radius: 11px;
  border: none;
  padding-left: 15px;
  padding-right: 18px;
}

.balance-btn_left {
  align-items: center;
}

.wallet-icon {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.main-font {
  font-size: 14px;
  color: #3C495B;
  line-height: 100%;
}

.link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  opacity: 0.5;
}

.link-wrapper:hover {
  opacity: 1;
}

.link-icon {
  width: 24px;
  height: 24px;
  margin-right: 28px;
}

.bottom-button {
  display: flex;
  align-items: center;
  border: none;
  outline: none !important;
  width: 100%;
  padding: 0;
  opacity: 0.5;
  margin-bottom: 15px;
}

.bottom-button:hover {
  
  opacity: 1;
}

.exit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  background: #FFC215;
  border-radius: 11px;
  border: none;
  outline: none !important;
}

.exit-btn-text {
  font-size: 16px;
  font-weight: bold;
  color: #3C495B;
  line-height: 100%;
}