.img{
border-radius: 100%;
overflow: hidden;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
width: 108px;
height: 108px;
}

span.bonus {
width: 125px;
height: 35px;
padding: 6px 10px;
background-color: #FFF9E5;
font-weight: 700;
color: #F4BE0C;
border-radius: 6px;
font-size: 16px;
}

.cab{
  display: flex;
}
.cabinet {
width: 85%;
padding: 40px 25px;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
background: #fff;
height: 188px;
border-radius: 5px 0 0 5px;
position: relative;
color: #3C495B; }
.cabinet-user {
width: 286px;}
.cabinet-user__photo {
float: left;
margin-right: 28px; }
.cabinet-user-data{
  margin-top: 22px;
border-right: 1px solid #F3F3F3;
}
.cabinet-user__name {
font-weight: 700;
margin-bottom: 12px; }
.cabinet-contact {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
margin-right: 74px;
padding: 22px 0;
}
.cabinet-contact .img-phone {
display: block;
width: 20px;
height: 20px;
margin: 0 auto;
text-align: center;
margin-bottom: 17px;
background: url("/assets/img/snip.svg") no-repeat;
background-position: -50px -5px; }
.cabinet-contact .img-mail {
display: block;
margin: 0 auto;
text-align: center;
width: 25px;
height: 19px;
margin-bottom: 17px;
background: url("/assets/img/snip.svg") no-repeat;
background-position: -50px -55px; }
.cabinet-contact__phone {
border-right: 1px solid #F3F3F3; }

.cabinet-contact__phone,
.cabinet-contact__email {
  padding: 0 40px;
  width: 210px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;}
.cabinet-level {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
width: 275px; }
.cabinet-level__img {
background: url("/assets/img/circle.svg") no-repeat;
padding: 30px 20px 0;
width: 100px;
height: 100px;
margin-right: 26px;
text-align: center;
font-size: 12px; }
.cabinet-level__img span {
font-weight: 700;
color: #FFC400;
font-size: 16px; }
.cabinet-level__name {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
width: 150px;
font-weight: 500;
padding-top: 17px;
margin-bottom: 14px;
font-size: 14px; }
.cabinet-level__name span {
font-size: 16px;
font-weight: 700;
line-height: 1.3; }
.cabinet-edit {
width: 15%;
height: 188px;
background: #fff;
border-left: 1px solid #F3F3F3;
padding-top: 45px;
border-radius: 0 5px 5px 0;
padding-right: 39px;
text-align: right; }
.cabinet-edit__link {
display: block;
margin-bottom: 17px;
font-size: 14px;
color: #3C495B;
text-decoration: underline; }

/*-------------------------------*/
.quest-menu {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
&:first-child{
padding-top: 30px;
} 
}
.quest-menu a {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
padding-top: 10px;
width: 265px;
height: 43px;
color: #3C495B; 
font-weight: 700;
margin-right: 8px;
text-decoration: none; }
.quest-menu__upcoming .img-calen {
width: 25px;
height: 25px;
margin-right: 15px;
background: url("/assets/img/snip.svg");
background-position: -50px -104px; }
.quest-menu__favorite .img-heart {
width: 24px;
height: 24px;
margin-right: 15px;
background: url("/assets/img/snip.svg");
background-position: -150px 0px; }
.quest-menu__passed .img-check {
  width: 26px;
  height: 25px;
  margin-right: 15px;
  background: url("/assets/img/snip.svg");
  background-position: -100px -103px; }
      
  

 