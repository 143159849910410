/*basic*/

$orangeColor: #FFC400;
$silverColor: #5F717C;
$greyColor: #3C495B;
$bgColor: #F8F8F8;
$mainColor: #F5F7F8;
$borderColor: #D9E0EA;
$labelColor: #909090;

$greenColor: #40C135;
$redColor: #E25757;


// header modal

.contentArea {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #F0F0F3;
}
 
.header-modal {
  margin-bottom: 35px;
}

.text-sm {
  font-size: 12px;
  color: #9ea4ad;
}

.form-control {
  border-radius: 12px;
} 

.labeled {
  color: #919EAF;
  font-size: 16px;
  font-weight: 300;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hr_style {
  border-bottom: 1px solid #DEEBF1;
}

.flex {
  display: flex;
  align-items: center;
}


.header-id {
  margin: 0 30px 0 0;
  color: #3C495B;
  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
}

.right-side {
  justify-content: flex-end;
}

.confirm-btn {
  background: #FFC215;
  border-radius: 8px;
  outline: none !important;
  border: none;
  padding: 8px 45px;
  color: #3C495B;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
}

.header-input {
  background-color:  #F1F7FA;
  border-radius: 7px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border: 0;
  margin-right: 20px;
}

.red-text {
  color: #F16868;
  font-weight: bold;
}

.header-btn {
  display: flex;
  align-items: center;
  background: #EEF2F4;
  border: 1px solid #DCE1E9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 12px 6px 6px;
}

.btn-with-margin {
  margin-right: 10px;
}

.header-btn-img {
  margin-right: 10px;
  width: auto;
}

.header-btn-text {
  color: #3C495B;
  font-size: 16px;
  margin: 0;
  line-height: 100%;
}

.components-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

a.component {
  transition: 0.4s all;
  &:hover {
    text-decoration: none;
    box-shadow: 0 0 5px #ccc;
  }
 // cursor: pointer;
}

.component {
  height: 115px;
  width: 32%;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 1px 20px rgba(126, 126, 126, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.component-right-side {
  border-left: 1px solid #E9F6FC;
  padding-left: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-text  {
  margin-bottom: 10px !important;
}

.component-left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.component-side {
  height: 100%;
  padding: 15px 0;
}

.text-big-bold {
  color: #3C495B;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  line-height: 100%;
}

.text-main {
  color: #3C495B;
  font-size: 14px;
  margin: 0;
  line-height: 100%;
}

.text-main-bold {
  color: #3C495B;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  line-height: 100%;
}
// header modal


.color-green {
  color: #14B16B;
  font-weight: 700;
}

.color-red {
  color: #DE6A63;
  font-weight: 700;
}

.indicator {
  width: 10px;
  height: 10px;
  background: #ccc;
  border-radius: 100px;
  &.success {
    background: $greenColor;
  }
  &.warning {
    background: $orangeColor;
  }
  &.error {
    background: $redColor;
  }
}

.rightPopUp {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px #00000038;
  padding: 20px 40px;
  z-index: 99;



  h5 {
    font-size: 18px;
  }

  p {
    color: #888;
    margin: 0;
  }
}

textarea.form-control {
  min-height: 100px;
  border: 1px solid #d9e0ea;
  border-radius: 13px;
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

img {
  width: 100%;
}

.emo {
  width: 30px;
}

.btn-all {
  background-color: #ffffff !important;
    border: 1px solid #f8c636 !important;
    margin: 20px 0;
    color: #3b485b !important;
    border-radius: 8px !important;
    padding: 10px 20px !important;
    font-weight: 700 !important;
}

*{
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}
html, body{
  height: 100%;
}

.ember-basic-dropdown-content {
  z-index: 2000 !important;
}

body{
  padding: 20px 30px 20px 310px !important;
  background: $mainColor !important;
  min-width: 1370px !important;
  position: relative;
}

ul, li{
  list-style: disc;
  margin: 0;
  padding: 0;
}

.contentTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-right: 20px;
    color: #3b495b;
    margin-bottom: 0;
  }

  button {
    border-radius: 100%;
    height: 40px;
    width: 40px;
    font-size: 20px;
    display: flex;
    background: transparent;
    border: 1px solid #f8c434;
    color: #f8c636;
    outline: none !important;
    align-items: center;
    justify-content: center;
  }

  .count-notifications {
    flex-grow: 1;
    align-items: center;
    text-align: end;
    font-size: 14px;
    font-weight: 400;
    color: #3b495b;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .count-notifications_text {
    margin: 0;
  }

  .count-notifications_numbers {
    margin: 0;
    margin-right: 166px;
  }
}

.namePage{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: $greyColor;
}

.closeButton {
  position: absolute;
  right: -63px;
  top: -31px;
  color: #ffc62b;
  font-size: 30px;
}


.flash-message {
  position: fixed !important;
  margin-bottom: 0 !important;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  z-index: 9999 !important;
}

.alert-danger {
  background: #DE6A63!important;
  color: #fff!important;
}

.alert-success {
  background: #14B16B!important;
  color: #fff!important;
}

.container{
  min-width: 980px;
  max-width: 1440px !important;
  padding: 0 40px;
}

.dropdown-toggle {
  &:after {
    content: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 !important;
    background: transparent !important;
}



@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #252a37 9%, #303954 25%, #252a37 40%);
  background-size: 783px 122px !important;
  position: relative !important;
}


@media only screen and (max-width: 768px) {

  .visiting-nav {
    display: block !important;
  }

  .visiting-nav {
    display: block !important;
  }

  .statistic {
    width: auto !important;
    display: flex;
    margin: 10px;
    tbody {
      display: flex;
      position: relative;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0 0 !important;
    }

    th, td {
      flex-basis: 100%;
      text-align: left;
      white-space: nowrap;
      display: flex;
      padding: 2px;
      
    }
    th {
      font-weight: bold;
    }
  }
  

  .visiting-nav div.form-group .ember-power-select-trigger, .formElement {
    margin: 10px !important;
  }

  body, html {
    padding: 0 0 0 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    height: 100%;
    min-height: 100%;
  }

  .viewNavWrap {
    display: block;
    padding: 20px;
  }

  .lightTableWrap {
    border-radius: 0 !important;
    overflow: auto !important;
    .ember-light-table {
      width: 1000px;
    }
  }

  .closeButton {
    right: -13px;
    top: -20px;
  }

  .tableSearch {
    position: fixed !important;
    background-color: #fff;
  }

  .components-wrapper, .flexRow {
    display: block !important;
  }

  .confirm-btn {
    width: 100%!important;
    margin-top: 20px!important;
  }

  .flexRow > div {
    margin-bottom: 10px !important;
  }

  .component {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .viewNavWrap {
    display: block !important;
    a {
      display: block;
    }
    button {
      margin: 20px 0 0 0 !important;
      top: 0 !important;
      left: 0 !important;
      position: relative  !important;
    }
  }

  .save {
    position: relative !important;
    top: 0;

    button {
      width: 100%;
    }
  }

  .button {
    border: 1px solid #ccc;
    background-color: #fff;
    display: block;
    margin: 20px;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .contentTitle {
    padding: 20px 0 0 60px;
    margin-bottom: 15px;
    height: 60px;
  }

  .login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  body > .ember-view {
    height: 100%;
  }

  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 18em
  }

  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em
  }
}