.ember-burger-menu.bm--open-door {
	-webkit-perspective: 1500px;
	perspective: 1500px;

	> .bm-outlet {
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}

	#{$bm-menu} {
		opacity: 1;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);

		&::after {
			display: none;
		}
	}

	&.right {
		> .bm-outlet {
			-webkit-transform-origin: 0% 50%;
			transform-origin: 0% 50%;
		}

		#{$bm-menu} {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
		}
	}

	&.is-open {
		> .bm-outlet {
			-webkit-transform: rotateY(-10deg);
			transform: rotateY(-10deg);
		}

		#{$bm-menu} {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}

		&.right {
			> .bm-outlet {
				-webkit-transform: rotateY(10deg);
				transform: rotateY(10deg);
			}
		}
	}
}
