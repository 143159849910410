.ember-burger-menu.bm--push {
	#{$bm-menu} {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	&.right {
		#{$bm-menu} {
			-webkit-transform: translate3d(100%, 0, 0);
			transform: translate3d(100%, 0, 0);
		}
	}
}
